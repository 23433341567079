import type { ActiveOrderQuery, SubscribeToEcomailInput } from '#build/graphql-operations'

type EcotrackArgs = 'trackPageView' | 'setUserIdFromLocation' | 'addTrans' | 'addItem' | 'trackTrans' | 'setUserId' | 'trackUnstructEvent' | 'trackStructEvent'

declare global {
  interface Window { ecotrack: (arg: EcotrackArgs, ...args: any) => void }
}

class EcomailHelper {
  private constructor() {}

  public static init = function (appId?: string | null) {
    if (!appId)
      return

    const scriptContent = `
;(function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments)
};p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;
n.src=w;g.parentNode.insertBefore(n,g)}}(window,document,"script","//d70shl7vidtft.cloudfront.net/ecmtr-2.4.2.js","ecotrack"));
window.ecotrack('newTracker', 'cf', 'd2dpiwfhf3tz0r.cloudfront.net', { /* Initialise a tracker */
appId: '${appId}'
});
`
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = scriptContent
    document.head.appendChild(script)
  }

  public static toFixed = (amount: string | number) => (Number(amount) / 100).toFixed(2)
}

export default defineNuxtPlugin(async () => {
  const { init, toFixed } = EcomailHelper

  const { activeOrder } = useActiveOrder()
  const { activeCustomer } = useAuth()

  const customerEmail = ref('')

  // this can be used as a source of appId from backend
  // const { data: { getEcomailAppId: appId } = {} } = await useGraphqlQuery('getEcomailAppId') as { data: GetEcomailAppIdQuery }

  init('exalted')

  const subscribeUser = async (input: SubscribeToEcomailInput) => {
    await useGraphqlMutation('subscribeToEcomail', {
      input,
    })
  }

  const trackCartChange = async (activeOrder: ActiveOrderQuery['activeOrder']) => {
    if (!customerEmail.value)
      return

    const products = activeOrder?.lines?.reduce<any[]>((acc, line) => {
      const { quantity, id, ...rest } = line
      Array(quantity).fill('').forEach(() => acc.push({
        productId: rest?.productVariant?.product?.id,
        img_url: rest?.featuredAsset?.preview,
        url: `${window.location.host}/products/${rest?.productVariant?.product?.id}`,
        name: rest?.productVariant?.product?.name,
        price: toFixed(rest?.productVariant?.price),
      }))
      return acc
    }, [])

    window.ecotrack('trackUnstructEvent', {
      schema: '',
      data: {
        action: 'Basket',
        products,
      },
    })
  }

  const trackOrderPlaced = async (activeOrder: ActiveOrderQuery['activeOrder']) => {
    if (!customerEmail.value)
      return

    const taxTotal = activeOrder?.taxSummary.reduce((acc, tax) => acc += tax.taxTotal, 0) || 0

    //            1. operation,    2.orderId,    3.shop name,   4.total,                       5.tax,                 6.transport,                          7.city,                         8.district/region,                       9.country
    window.ecotrack('addTrans', activeOrder?.id, 'Exalted', toFixed(activeOrder?.total), toFixed(taxTotal), toFixed(activeOrder?.shipping), activeOrder?.shippingAddress?.city, activeOrder?.shippingAddress?.city, activeOrder?.shippingAddress?.countryCode)

    //                                              1. operation  2.orderId                3. slug                        4. product name                      5. variant              6. price per unit           7. quantity
    activeOrder?.lines?.forEach(el => window.ecotrack('addItem', activeOrder?.id, el.productVariant?.product?.slug, el.productVariant?.product?.name, el.productVariant?.name, toFixed(el.productVariant?.price), el.quantity))

    window.ecotrack('trackTrans')
  }

  const trackLastViewed = async (productId: string) => {
    if (!customerEmail.value)
      return
    window.ecotrack('trackStructEvent', 'ECM_PRODUCT_VIEW', productId)
  }

  watch(activeOrder, (activeOrder, prev) => {
    const email = activeOrder?.customer?.emailAddress

    customerEmail.value = email || ''
    window.ecotrack('setUserId', email)

    if (activeOrder && (activeOrder?.subTotal !== prev?.subTotal)) {
      trackCartChange(activeOrder)
    }
  })

  watch(activeCustomer, (customer) => {
    customerEmail.value = customer?.emailAddress || ''
    window.ecotrack('setUserId', customer?.emailAddress)
  }, { immediate: true })

  return {
    provide: {
      ecomail: {
        subscribeUser,
        trackCartChange,
        trackOrderPlaced,
        trackLastViewed,
      },
    },
  }
})

interface EcomailPlugin {
  subscribeUser: (input: SubscribeToEcomailInput) => Promise<void>
  trackCartChange: (activeOrder: ActiveOrderQuery['activeOrder']) => void
  trackOrderPlaced: (activeOrder: ActiveOrderQuery['activeOrder']) => void
  trackLastViewed: (productId: string) => void
}

declare module '#app' {
  interface NuxtApp {
    $ecomail: EcomailPlugin
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $ecomail: EcomailPlugin
  }
}
