import provideState_IaJzs1zTe7 from "/usr/local/app/node_modules/.pnpm/nuxt-graphql-middleware@4.1.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_graphq_ia7b7akhkjcko7wtcanroyon5a/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.js";
import revive_payload_client_VitxX5DUkJ from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._mm2akim2wyqpbvhugrrydnc6mq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_r0RTCkaK7n from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._mm2akim2wyqpbvhugrrydnc6mq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Vv0bf5Di7F from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._mm2akim2wyqpbvhugrrydnc6mq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_RBP9SOVdT4 from "/usr/local/app/node_modules/.pnpm/nuxt-site-config@2.2.16_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4_terser@5.3_perymwdu73axakl4mqpynixhg4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_PxEKQF32m8 from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._mm2akim2wyqpbvhugrrydnc6mq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ZEQT3xtWHx from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._mm2akim2wyqpbvhugrrydnc6mq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_zKadI7Azxc from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._mm2akim2wyqpbvhugrrydnc6mq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_06MGcjvu7l from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._mm2akim2wyqpbvhugrrydnc6mq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/local/app/.nuxt/components.plugin.mjs";
import prefetch_client_8maRX4Y1G8 from "/usr/local/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._mm2akim2wyqpbvhugrrydnc6mq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_xoasCwIKzj from "/usr/local/app/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_6Ph1iQzNHk from "/usr/local/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import nuxt_plugin_32B1TYVd89 from "/usr/local/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_HTeH6WuMyp from "/usr/local/app/node_modules/.pnpm/v-wave@2.0.0_magicast@0.3.5_rollup@4.21.2_vue@3.5.3_typescript@5.6.2__webpack-sources@3.2.3/node_modules/v-wave/nuxt/runtime/plugin.js";
import plugin_KHpJEtIfD7 from "/usr/local/app/node_modules/.pnpm/@nuxtjs+device@3.2.2_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_h3@1.12.0_iored_vgveggpxhrohvcp2sqdxx4t7ly/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_8eEr9kP1rK from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_magicast@0.3.5_rollup@4.21.2_vue@3.5.3_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_gYVYANtJxP from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_magicast@0.3.5_rollup@4.21.2_vue@3.5.3_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_9UU2JyR7Mg from "/usr/local/app/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4_terser@5.32.0__vu_tswk6673h54eskawq4jldebxku/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_EXqO6emihY from "/usr/local/app/node_modules/.pnpm/@nuxt+image@1.8.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/@nuxt/image/dist/runtime/plugin.js";
import unocss_MzCDxu9LMj from "/usr/local/app/.nuxt/unocss.mjs";
import plugin_HMUycbzStU from "/usr/local/app/modules/pino/runtime/plugin.ts";
import plugin_xDtleEcACi from "/usr/local/app/modules/gtm/runtime/plugin.ts";
import _0_graphqlConfig_yaUZblG674 from "/usr/local/app/plugins/0.graphqlConfig.ts";
import _1_auth_slaK5pfDbE from "/usr/local/app/plugins/1.auth.ts";
import _2_activeOrder_yYINElYs5H from "/usr/local/app/plugins/2.activeOrder.ts";
import _3_ecomail_client_He1EpcL9SI from "/usr/local/app/plugins/3.ecomail.client.ts";
import collections_pRcEP3swaI from "/usr/local/app/plugins/collections.ts";
import foxentry_client_gcy3vwc779 from "/usr/local/app/plugins/foxentry.client.ts";
import history_client_90Xi9yF6BH from "/usr/local/app/plugins/history.client.ts";
import observed_6bGIygi7FH from "/usr/local/app/plugins/observed.ts";
import scroll_client_HJUo2UKx9n from "/usr/local/app/plugins/scroll.client.ts";
import vee_validate_KcKlKmvCrJ from "/usr/local/app/plugins/vee-validate.ts";
export default [
  provideState_IaJzs1zTe7,
  revive_payload_client_VitxX5DUkJ,
  unhead_r0RTCkaK7n,
  router_Vv0bf5Di7F,
  _0_siteConfig_RBP9SOVdT4,
  payload_client_PxEKQF32m8,
  navigation_repaint_client_ZEQT3xtWHx,
  check_outdated_build_client_zKadI7Azxc,
  chunk_reload_client_06MGcjvu7l,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8maRX4Y1G8,
  plugin_xoasCwIKzj,
  plugin_6Ph1iQzNHk,
  nuxt_plugin_32B1TYVd89,
  plugin_HTeH6WuMyp,
  plugin_KHpJEtIfD7,
  switch_locale_path_ssr_8eEr9kP1rK,
  i18n_gYVYANtJxP,
  plugin_9UU2JyR7Mg,
  plugin_EXqO6emihY,
  unocss_MzCDxu9LMj,
  plugin_HMUycbzStU,
  plugin_xDtleEcACi,
  _0_graphqlConfig_yaUZblG674,
  _1_auth_slaK5pfDbE,
  _2_activeOrder_yYINElYs5H,
  _3_ecomail_client_He1EpcL9SI,
  collections_pRcEP3swaI,
  foxentry_client_gcy3vwc779,
  history_client_90Xi9yF6BH,
  observed_6bGIygi7FH,
  scroll_client_HJUo2UKx9n,
  vee_validate_KcKlKmvCrJ
]