import { default as indexZRmv4rwJvqMeta } from "/usr/local/app/pages/index.vue?macro=true";
import { default as indexbcW1kPi65kMeta } from "/usr/local/app/pages/account/addresses/index.vue?macro=true";
import { default as indexf0kufjSeQTMeta } from "/usr/local/app/pages/account/index.vue?macro=true";
import { default as influencerLVsohnAPjzMeta } from "/usr/local/app/pages/account/influencer.vue?macro=true";
import { default as _91code_93Z5gRwhAl1xMeta } from "/usr/local/app/pages/account/orders/[code].vue?macro=true";
import { default as indexyavSc54tHJMeta } from "/usr/local/app/pages/account/orders/index.vue?macro=true";
import { default as securityGIscjYEtcLMeta } from "/usr/local/app/pages/account/security.vue?macro=true";
import { default as accountkzLoSvJwtmMeta } from "/usr/local/app/pages/account.vue?macro=true";
import { default as change_45emailUR6mw1en5xMeta } from "/usr/local/app/pages/auth/change-email.vue?macro=true";
import { default as loginF3gIq4xjsbMeta } from "/usr/local/app/pages/auth/login.vue?macro=true";
import { default as logoutgfTlJJpOwXMeta } from "/usr/local/app/pages/auth/logout.vue?macro=true";
import { default as new_45passwordHKAqxDljR1Meta } from "/usr/local/app/pages/auth/new-password.vue?macro=true";
import { default as registerD1YI4k4ySdMeta } from "/usr/local/app/pages/auth/register.vue?macro=true";
import { default as carousel8gICzjaUtSMeta } from "/usr/local/app/pages/carousel.vue?macro=true";
import { default as _91code_93n8bQXXLUreMeta } from "/usr/local/app/pages/checkout/confirmation/[code].vue?macro=true";
import { default as indexS4P2YxqBHcMeta } from "/usr/local/app/pages/checkout/index.vue?macro=true";
import { default as _91_46_46_46slug_93nXxB96PSaKMeta } from "/usr/local/app/pages/collections/[...slug].vue?macro=true";
import { default as contactU3U0nxWLZbMeta } from "/usr/local/app/pages/contact.vue?macro=true";
import { default as forgot_45passwordmK5vu79aO6Meta } from "/usr/local/app/pages/forgot-password.vue?macro=true";
import { default as _91_91slug_93_939lFgEk0npoMeta } from "/usr/local/app/pages/products/[id]/[[slug]].vue?macro=true";
import { default as searchYhxkQS4hULMeta } from "/usr/local/app/pages/search.vue?macro=true";
import { default as _91slug_93Wy0ZMWlk3DMeta } from "/usr/local/app/pages/stores/[id]/[slug].vue?macro=true";
import { default as wishlistokolbcawgtMeta } from "/usr/local/app/pages/wishlist.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: accountkzLoSvJwtmMeta?.name,
    path: "/en/account",
    meta: accountkzLoSvJwtmMeta || {},
    component: () => import("/usr/local/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses___en",
    path: "addresses",
    component: () => import("/usr/local/app/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "",
    component: () => import("/usr/local/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-influencer___en",
    path: "influencer",
    component: () => import("/usr/local/app/pages/account/influencer.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-code___en",
    path: "orders/:code()",
    component: () => import("/usr/local/app/pages/account/orders/[code].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___en",
    path: "orders",
    meta: indexyavSc54tHJMeta || {},
    component: () => import("/usr/local/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-security___en",
    path: "security",
    component: () => import("/usr/local/app/pages/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountkzLoSvJwtmMeta?.name,
    path: "/cs/account",
    meta: accountkzLoSvJwtmMeta || {},
    component: () => import("/usr/local/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses___cs",
    path: "addresses",
    component: () => import("/usr/local/app/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account___cs",
    path: "",
    component: () => import("/usr/local/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-influencer___cs",
    path: "influencer",
    component: () => import("/usr/local/app/pages/account/influencer.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-code___cs",
    path: "orders/:code()",
    component: () => import("/usr/local/app/pages/account/orders/[code].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___cs",
    path: "orders",
    meta: indexyavSc54tHJMeta || {},
    component: () => import("/usr/local/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-security___cs",
    path: "security",
    component: () => import("/usr/local/app/pages/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountkzLoSvJwtmMeta?.name,
    path: "/sk/account",
    meta: accountkzLoSvJwtmMeta || {},
    component: () => import("/usr/local/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses___sk",
    path: "addresses",
    component: () => import("/usr/local/app/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account___sk",
    path: "",
    component: () => import("/usr/local/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-influencer___sk",
    path: "influencer",
    component: () => import("/usr/local/app/pages/account/influencer.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-code___sk",
    path: "orders/:code()",
    component: () => import("/usr/local/app/pages/account/orders/[code].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___sk",
    path: "orders",
    meta: indexyavSc54tHJMeta || {},
    component: () => import("/usr/local/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-security___sk",
    path: "security",
    component: () => import("/usr/local/app/pages/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountkzLoSvJwtmMeta?.name,
    path: "/pl/account",
    meta: accountkzLoSvJwtmMeta || {},
    component: () => import("/usr/local/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses___pl",
    path: "addresses",
    component: () => import("/usr/local/app/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account___pl",
    path: "",
    component: () => import("/usr/local/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-influencer___pl",
    path: "influencer",
    component: () => import("/usr/local/app/pages/account/influencer.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-code___pl",
    path: "orders/:code()",
    component: () => import("/usr/local/app/pages/account/orders/[code].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___pl",
    path: "orders",
    meta: indexyavSc54tHJMeta || {},
    component: () => import("/usr/local/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-security___pl",
    path: "security",
    component: () => import("/usr/local/app/pages/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountkzLoSvJwtmMeta?.name,
    path: "/hu/account",
    meta: accountkzLoSvJwtmMeta || {},
    component: () => import("/usr/local/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses___hu",
    path: "addresses",
    component: () => import("/usr/local/app/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account___hu",
    path: "",
    component: () => import("/usr/local/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-influencer___hu",
    path: "influencer",
    component: () => import("/usr/local/app/pages/account/influencer.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-code___hu",
    path: "orders/:code()",
    component: () => import("/usr/local/app/pages/account/orders/[code].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___hu",
    path: "orders",
    meta: indexyavSc54tHJMeta || {},
    component: () => import("/usr/local/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-security___hu",
    path: "security",
    component: () => import("/usr/local/app/pages/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountkzLoSvJwtmMeta?.name,
    path: "/ro/account",
    meta: accountkzLoSvJwtmMeta || {},
    component: () => import("/usr/local/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses___ro",
    path: "addresses",
    component: () => import("/usr/local/app/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account___ro",
    path: "",
    component: () => import("/usr/local/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-influencer___ro",
    path: "influencer",
    component: () => import("/usr/local/app/pages/account/influencer.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-code___ro",
    path: "orders/:code()",
    component: () => import("/usr/local/app/pages/account/orders/[code].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___ro",
    path: "orders",
    meta: indexyavSc54tHJMeta || {},
    component: () => import("/usr/local/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-security___ro",
    path: "security",
    component: () => import("/usr/local/app/pages/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-change-email___en",
    path: "/en/auth/change-email",
    component: () => import("/usr/local/app/pages/auth/change-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-change-email___cs",
    path: "/cs/auth/change-email",
    component: () => import("/usr/local/app/pages/auth/change-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-change-email___sk",
    path: "/sk/auth/change-email",
    component: () => import("/usr/local/app/pages/auth/change-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-change-email___pl",
    path: "/pl/auth/change-email",
    component: () => import("/usr/local/app/pages/auth/change-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-change-email___hu",
    path: "/hu/auth/change-email",
    component: () => import("/usr/local/app/pages/auth/change-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-change-email___ro",
    path: "/ro/auth/change-email",
    component: () => import("/usr/local/app/pages/auth/change-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___en",
    path: "/en/auth/login",
    meta: loginF3gIq4xjsbMeta || {},
    component: () => import("/usr/local/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___cs",
    path: "/cs/auth/login",
    meta: loginF3gIq4xjsbMeta || {},
    component: () => import("/usr/local/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___sk",
    path: "/sk/auth/login",
    meta: loginF3gIq4xjsbMeta || {},
    component: () => import("/usr/local/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___pl",
    path: "/pl/auth/login",
    meta: loginF3gIq4xjsbMeta || {},
    component: () => import("/usr/local/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___hu",
    path: "/hu/auth/login",
    meta: loginF3gIq4xjsbMeta || {},
    component: () => import("/usr/local/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___ro",
    path: "/ro/auth/login",
    meta: loginF3gIq4xjsbMeta || {},
    component: () => import("/usr/local/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout___en",
    path: "/en/auth/logout",
    component: () => import("/usr/local/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout___cs",
    path: "/cs/auth/logout",
    component: () => import("/usr/local/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout___sk",
    path: "/sk/auth/logout",
    component: () => import("/usr/local/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout___pl",
    path: "/pl/auth/logout",
    component: () => import("/usr/local/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout___hu",
    path: "/hu/auth/logout",
    component: () => import("/usr/local/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout___ro",
    path: "/ro/auth/logout",
    component: () => import("/usr/local/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-new-password___en",
    path: "/en/auth/new-password",
    component: () => import("/usr/local/app/pages/auth/new-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-new-password___cs",
    path: "/cs/auth/new-password",
    component: () => import("/usr/local/app/pages/auth/new-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-new-password___sk",
    path: "/sk/auth/new-password",
    component: () => import("/usr/local/app/pages/auth/new-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-new-password___pl",
    path: "/pl/auth/new-password",
    component: () => import("/usr/local/app/pages/auth/new-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-new-password___hu",
    path: "/hu/auth/new-password",
    component: () => import("/usr/local/app/pages/auth/new-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-new-password___ro",
    path: "/ro/auth/new-password",
    component: () => import("/usr/local/app/pages/auth/new-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-register___en",
    path: "/en/auth/register",
    meta: registerD1YI4k4ySdMeta || {},
    component: () => import("/usr/local/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-register___cs",
    path: "/cs/auth/register",
    meta: registerD1YI4k4ySdMeta || {},
    component: () => import("/usr/local/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-register___sk",
    path: "/sk/auth/register",
    meta: registerD1YI4k4ySdMeta || {},
    component: () => import("/usr/local/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-register___pl",
    path: "/pl/auth/register",
    meta: registerD1YI4k4ySdMeta || {},
    component: () => import("/usr/local/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-register___hu",
    path: "/hu/auth/register",
    meta: registerD1YI4k4ySdMeta || {},
    component: () => import("/usr/local/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-register___ro",
    path: "/ro/auth/register",
    meta: registerD1YI4k4ySdMeta || {},
    component: () => import("/usr/local/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "carousel___en",
    path: "/en/carousel",
    component: () => import("/usr/local/app/pages/carousel.vue").then(m => m.default || m)
  },
  {
    name: "carousel___cs",
    path: "/cs/carousel",
    component: () => import("/usr/local/app/pages/carousel.vue").then(m => m.default || m)
  },
  {
    name: "carousel___sk",
    path: "/sk/carousel",
    component: () => import("/usr/local/app/pages/carousel.vue").then(m => m.default || m)
  },
  {
    name: "carousel___pl",
    path: "/pl/carousel",
    component: () => import("/usr/local/app/pages/carousel.vue").then(m => m.default || m)
  },
  {
    name: "carousel___hu",
    path: "/hu/carousel",
    component: () => import("/usr/local/app/pages/carousel.vue").then(m => m.default || m)
  },
  {
    name: "carousel___ro",
    path: "/ro/carousel",
    component: () => import("/usr/local/app/pages/carousel.vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation-code___en",
    path: "/en/checkout/confirmation/:code()",
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation-code___cs",
    path: "/cs/checkout/confirmation/:code()",
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation-code___sk",
    path: "/sk/checkout/confirmation/:code()",
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation-code___pl",
    path: "/pl/checkout/confirmation/:code()",
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation-code___hu",
    path: "/hu/checkout/confirmation/:code()",
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation-code___ro",
    path: "/ro/checkout/confirmation/:code()",
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: "checkout___en",
    path: "/en/checkout",
    meta: indexS4P2YxqBHcMeta || {},
    component: () => import("/usr/local/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___cs",
    path: "/cs/checkout",
    meta: indexS4P2YxqBHcMeta || {},
    component: () => import("/usr/local/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___sk",
    path: "/sk/checkout",
    meta: indexS4P2YxqBHcMeta || {},
    component: () => import("/usr/local/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___pl",
    path: "/pl/checkout",
    meta: indexS4P2YxqBHcMeta || {},
    component: () => import("/usr/local/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___hu",
    path: "/hu/checkout",
    meta: indexS4P2YxqBHcMeta || {},
    component: () => import("/usr/local/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___ro",
    path: "/ro/checkout",
    meta: indexS4P2YxqBHcMeta || {},
    component: () => import("/usr/local/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-slug___en",
    path: "/en/collections/:slug(.*)*",
    meta: _91_46_46_46slug_93nXxB96PSaKMeta || {},
    component: () => import("/usr/local/app/pages/collections/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "collections-slug___cs",
    path: "/cs/collections/:slug(.*)*",
    meta: _91_46_46_46slug_93nXxB96PSaKMeta || {},
    component: () => import("/usr/local/app/pages/collections/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "collections-slug___sk",
    path: "/sk/collections/:slug(.*)*",
    meta: _91_46_46_46slug_93nXxB96PSaKMeta || {},
    component: () => import("/usr/local/app/pages/collections/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "collections-slug___pl",
    path: "/pl/collections/:slug(.*)*",
    meta: _91_46_46_46slug_93nXxB96PSaKMeta || {},
    component: () => import("/usr/local/app/pages/collections/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "collections-slug___hu",
    path: "/hu/collections/:slug(.*)*",
    meta: _91_46_46_46slug_93nXxB96PSaKMeta || {},
    component: () => import("/usr/local/app/pages/collections/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "collections-slug___ro",
    path: "/ro/collections/:slug(.*)*",
    meta: _91_46_46_46slug_93nXxB96PSaKMeta || {},
    component: () => import("/usr/local/app/pages/collections/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/usr/local/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___cs",
    path: "/cs/contact",
    component: () => import("/usr/local/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___sk",
    path: "/sk/contact",
    component: () => import("/usr/local/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___pl",
    path: "/pl/contact",
    component: () => import("/usr/local/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___hu",
    path: "/hu/contact",
    component: () => import("/usr/local/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___ro",
    path: "/ro/contact",
    component: () => import("/usr/local/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___en",
    path: "/en/forgot-password",
    component: () => import("/usr/local/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___cs",
    path: "/cs/forgot-password",
    component: () => import("/usr/local/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___sk",
    path: "/sk/forgot-password",
    component: () => import("/usr/local/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___pl",
    path: "/pl/forgot-password",
    component: () => import("/usr/local/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___hu",
    path: "/hu/forgot-password",
    component: () => import("/usr/local/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___ro",
    path: "/ro/forgot-password",
    component: () => import("/usr/local/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/cs",
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sk",
    path: "/sk",
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pl",
    path: "/pl",
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___hu",
    path: "/hu",
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ro",
    path: "/ro",
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "products-id-slug___en",
    path: "/en/products/:id()/:slug?",
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "products-id-slug___cs",
    path: "/cs/products/:id()/:slug?",
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "products-id-slug___sk",
    path: "/sk/products/:id()/:slug?",
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "products-id-slug___pl",
    path: "/pl/products/:id()/:slug?",
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "products-id-slug___hu",
    path: "/hu/products/:id()/:slug?",
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "products-id-slug___ro",
    path: "/ro/products/:id()/:slug?",
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/usr/local/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___cs",
    path: "/cs/search",
    component: () => import("/usr/local/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___sk",
    path: "/sk/search",
    component: () => import("/usr/local/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___pl",
    path: "/pl/search",
    component: () => import("/usr/local/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___hu",
    path: "/hu/search",
    component: () => import("/usr/local/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___ro",
    path: "/ro/search",
    component: () => import("/usr/local/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "stores-id-slug___en",
    path: "/en/stores/:id()/:slug()",
    component: () => import("/usr/local/app/pages/stores/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stores-id-slug___cs",
    path: "/cs/stores/:id()/:slug()",
    component: () => import("/usr/local/app/pages/stores/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stores-id-slug___sk",
    path: "/sk/stores/:id()/:slug()",
    component: () => import("/usr/local/app/pages/stores/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stores-id-slug___pl",
    path: "/pl/stores/:id()/:slug()",
    component: () => import("/usr/local/app/pages/stores/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stores-id-slug___hu",
    path: "/hu/stores/:id()/:slug()",
    component: () => import("/usr/local/app/pages/stores/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stores-id-slug___ro",
    path: "/ro/stores/:id()/:slug()",
    component: () => import("/usr/local/app/pages/stores/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "wishlist___en",
    path: "/en/wishlist",
    component: () => import("/usr/local/app/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___cs",
    path: "/cs/wishlist",
    component: () => import("/usr/local/app/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___sk",
    path: "/sk/wishlist",
    component: () => import("/usr/local/app/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___pl",
    path: "/pl/wishlist",
    component: () => import("/usr/local/app/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___hu",
    path: "/hu/wishlist",
    component: () => import("/usr/local/app/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___ro",
    path: "/ro/wishlist",
    component: () => import("/usr/local/app/pages/wishlist.vue").then(m => m.default || m)
  }
]