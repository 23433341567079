import { ErrorCode } from '#graphql-operations'

export interface GraphQLErrorResult extends Error {
  code: ErrorCode | string
}

export class GraphQLBaseError extends Error implements GraphQLErrorResult {
  constructor(public code: ErrorCode | string, message: string) {
    super(message)
    this.name = this.constructor.name
    Error.captureStackTrace(this, this.constructor)
  }
}

/** Returned if the user authentication credentials are not valid */
export class InvalidCredentialsError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.INVALID_CREDENTIALS_ERROR, message)
  }
}

/** Returned if `authOptions.requireVerification` is set to `true` (which is the default) and an unverified user attempts to authenticate. */
export class NotVerifiedError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.NOT_VERIFIED_ERROR, message)
  }
}

/** Returned when attempting to register or verify a customer account without a password, when one is required. */
export class MissingPasswordError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.MISSING_PASSWORD_ERROR, message)
  }
}

/** Returned when attempting an operation that relies on the NativeAuthStrategy, if that strategy is not configured. */
export class NativeAuthStrategyError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.NATIVE_AUTH_STRATEGY_ERROR, message)
  }
}

/** Returned when attempting to register or verify a customer account where the given password fails password validation. */
export class PasswordValidationError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.PASSWORD_VALIDATION_ERROR, message)
  }
}

export class AddToObservedError extends GraphQLBaseError {
  constructor(message: string) {
    super('AddToObservedError', message)
  }
}

export class RemoveFromObservedError extends GraphQLBaseError {
  constructor(message: string) {
    super('RemoveFromObservedError', message)
  }
}

// Coupon code errors
export class CouponCodeExpiredError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.COUPON_CODE_EXPIRED_ERROR, message)
  }
}

export class CouponCodeInvalidError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.COUPON_CODE_INVALID_ERROR, message)
  }
}

export class CouponCodeLimitError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.COUPON_CODE_LIMIT_ERROR, message)
  }
}

// Shipping methods errors
export class IneligibleShippingMethodError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.INELIGIBLE_SHIPPING_METHOD_ERROR, message)
  }
}

export class NoActiveOrderError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.NO_ACTIVE_ORDER_ERROR, message)
  }
}

export class OrderModificationError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.ORDER_MODIFICATION_ERROR, message)
  }
}
